/* eslint-disable react/display-name */
import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { FluidImg } from 'src/embeds/image/image-dato';
import MDXComponents from 'src/components/mdx-components';
import { Section } from 'src/components/molecules/Section';
import { Grid, Column } from 'src/components/molecules/Grid';

export default function Drawer({ heading, callouts, customData }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCallout');
  customData = JSON.parse(customData);
  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        <h2>{heading}</h2>
        {callouts.length > 0 && (
          <Grid columns={Array(callouts.length).fill('1fr').join(' ')}>
            {callouts.map((callout, i) => {
              return (
                <Column key={i} as="article">
                  <FluidImg
                    data={callout.media}
                    maxWidth="500"
                    css={`
                      margin-bottom: 20px;
                    `}
                  />
                  <h3>{callout.heading}</h3>
                  {callout.content.length > 0 && (
                    <MDXRenderer>{callout.contentNode.childMdx.body}</MDXRenderer>
                  )}
                </Column>
              );
            })}
          </Grid>
        )}
      </Section>
    </MDXProvider>
  );
}
